import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import DriverNavbar from "./DriverNavbar";
import "../css/DriverAssignments.css"; // Create this CSS file for styling

import { formatDistanceToNow } from "date-fns";

import { useLoading } from "./LoadingContext";
import { CompanyContext } from "./CompanyContext";

const DriverAssignments = () => {
  const { showLoading, hideLoading } = useLoading();
  const [assignments, setAssignments] = useState([]);
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/assignments`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setAssignments(response.data);
        })
        .catch((error) => {
          console.error("Error fetching assignments:", error);
        })
        .finally(hideLoading);
    }
  }, [navigate]);

  const formattedDate = (date) => {
    return `${new Date(date).toLocaleString("en-US", {
      timeZone: "Australia/Melbourne",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  };

  return (
    <div className="flex flexColumn driver-assignments-container">
      <DriverNavbar page="DRIVER ASSIGNMENTS" route="assignments" />
      <div className="assignments-inner flex flexColumn">
        <h2 className="assign-title assignments-title">Driver Assignments</h2>
        <div className="assignments-box flex flexRow">
          {assignments.map((assignment) => (
            <a
              key={assignment._id}
              className="assignment"
              href={`/driver/assignments/${assignment._id}`}
            >
              <div className="assignment-item flex flexColumn">
                <p
                  className={`assignment-box-label assignment-status ${
                    assignment.status.toLowerCase() === "waiting for driver"
                      ? "assignment-normal"
                      : "assignment-accepted"
                  }`}
                >
                  {assignment.status.toLowerCase() === "waiting for driver"
                    ? "New Assignment"
                    : "Assigned"}
                </p>
                <p className="assignment-box-label assignment-code d-a-code">
                  {assignment.packageDetails.name}
                </p>
                <p className="assignment-box-label assignment-driver">
                  <img
                    className="assignment-truck a-user"
                    src={process.env.PUBLIC_URL + "/usericon.png"}
                    alt=""
                  />
                  {assignment.packageDetails.ownerName}
                  <br />
                  <img
                    className="assignment-truck a-location"
                    src={process.env.PUBLIC_URL + "/location.png"}
                    alt=""
                  />
                  {assignment.packageDetails.address}
                </p>
                <p className="assignment-box-label assignment-date">
                  {formattedDate(assignment.assignedAt)} <br />
                  <span className="assignment-dtn">
                    {"("}
                    {formatDistanceToNow(new Date(assignment.assignedAt), {
                      addSuffix: true,
                    })}
                    {")"}
                  </span>
                </p>
              </div>
            </a>
          ))}
          {assignments.length <= 0 ? (
            <div className="no-assignments">You don't have any assignments</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DriverAssignments;
