import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import DriverNavbar from "./DriverNavbar";
import { formatDistanceToNow } from "date-fns";

import mammoth from "mammoth";
import * as XLSX from "xlsx";

import { useLoading } from "./LoadingContext";
import { CompanyContext } from "./CompanyContext";

const AssignmentDelivery = () => {
  const { showLoading, hideLoading } = useLoading();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [assignment, setAssignment] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [photoPreviews, setPhotoPreviews] = useState([]);
  const { uniqueId } = useParams();
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/shipping/${uniqueId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setAssignment(response.data);

        })
        .catch((error) => {
          console.error("Error fetching assignment:", error);
          navigate("/driver/assignments");
        })
        .finally(hideLoading);
    }
  }, [uniqueId, navigate]);

  const handlePackagePicked = () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      axios
        .post(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/update-status`,
          { id: assignment._id },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setAssignment(response.data);
          alert("Status updated to 'Being Delivered'");
        })
        .catch((error) => {
          console.error("Error updating status:", error);
          alert("Failed to update status");
        });
    }
  };

  const handlePhotoUpload = async (e) => {
    if (buttonLoading) return;
    setButtonLoading(true);

    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      const files = Array.from(e.target.files);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("photos", file); // Append each photo to "photos" field
      });
      formData.append("id", assignment._id);

      axios
        .post(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/upload-photo-preview`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              console.log(`Upload Progress: ${progress}%`);
            },
          }
        )
        .then((res) => {
          alert("Photos uploaded successfully");
          setPhotos([]);
          setPhotoPreviews([]);
          setAssignment(res.data.assignment);
          setButtonLoading(false)
        })
        .catch((error) => {
          console.error("Error uploading photos:", error);
          alert(`Failed to upload photos: ${error}`);
          setButtonLoading(false)
        });
    }
  };

  const handlePhotoSubmit = () => {

    if (buttonLoading) return;
    setButtonLoading(true);

    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      const formData = new FormData();
      formData.append("id", assignment._id);

      axios
        .post(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/upload-photos`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json"
            },
            onUploadProgress: (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              console.log(`Upload Progress: ${progress}%`);
            },
          }
        )
        .then((res) => {
          alert("Photos uploaded successfully");
          setPhotos([]);
          setPhotoPreviews([]);
          setAssignment(res.data.assignment);
          setButtonLoading(false)
        })
        .catch((error) => {
          console.error("Error uploading photos:", error);
          alert(`Failed to upload photos: ${error}`);
          setButtonLoading(false)
        });
    }
  }

  const handleRemoveImg = (index) => {

    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      const formData = new FormData();
      formData.append("index", index);
      formData.append("id", assignment._id);

      axios
        .post(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/remove-photo`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              console.log(`Upload Progress: ${progress}%`);
            },
          }
        )
        .then((res) => {
          alert("Photo removed successfully");
          setPhotos([]);
          setPhotoPreviews([]);
          setAssignment(res.data.assignment);
          setButtonLoading(false)
        })
        .catch((error) => {
          console.error("Error removing photo:", error);
          alert(`Failed to remove photo: ${error}`);
          setButtonLoading(false)
        });
    }


  };

  if (!assignment) {
    return <div>Loading...</div>;
  }

  const formattedDate = (date) => {
    return `${new Date(date).toLocaleString("en-US", {
      timeZone: "Australia/Melbourne",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  };


  const viewFile = (filename) => {
    const token = Cookies.get("token");
    const fileExtension = filename.split(".").pop().toLowerCase();

    axios
      .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/pdf/${filename}`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const file = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(file);

        const container = document.querySelector(".pdf-list"); // Get the existing container

        // Clear previous previews
        const existingPreview = container.querySelector(".file-preview");
        if (existingPreview) {
          existingPreview.remove();
        }

        if (fileExtension === "pdf") {
          // Display PDF in iframe within the container
          let iframe = document.createElement("iframe");
          iframe.className = "file-preview"; // Add class to identify the preview element
          iframe.style.width = "100%";
          iframe.style.height = "50vh";
          iframe.src = url;
          container.appendChild(iframe);

          iframe.onload = () => {
            window.URL.revokeObjectURL(url);
          };
        } else if (fileExtension === "png" || fileExtension === "jpeg" || fileExtension === "jpg") {
          // Display image within the container
          const img = document.createElement("img");
          img.className = "file-preview"; // Add class to identify the preview element
          img.src = url;
          img.style.width = "100%";
          img.style.maxWidth = "37rem";
          img.style.height = "auto";
          img.alt = "Image Preview";
          container.appendChild(img);
        } else if (fileExtension === "docx") {
          // Use mammoth.js to render docx file
          mammoth.convertToHtml({ arrayBuffer: file }).then((result) => {
            const docxContainer = document.createElement("div");
            docxContainer.className = "file-preview";
            docxContainer.innerHTML = result.value; // The converted HTML
            container.appendChild(docxContainer);
          });
        } else if (["xls", "xlsx"].includes(fileExtension)) {
          // Use SheetJS to render Excel file
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const htmlString = XLSX.utils.sheet_to_html(workbook.Sheets[workbook.SheetNames[0]]);

            const excelContainer = document.createElement("div");
            excelContainer.className = "file-preview";
            excelContainer.innerHTML = htmlString;
            container.appendChild(excelContainer);
          };
          reader.readAsArrayBuffer(file);
        } else {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(file);
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          link.textContent = `Download ${filename.split("-")[2]}`;
          container.appendChild(link);
        }
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  const handleReturn = () => {
    setButtonLoading(true)

    const token = Cookies.get("token");

    axios
      .post(
        `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/return`,
        { uniqueId: uniqueId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        alert("Delivery returned succesfully!");
        setButtonLoading(false);
        window.location.reload(); // Refresh the page

      })
      .catch((error) => {
        setButtonLoading(false);
        console.error("Error returning delivery:", error);
      });
  };


  // Helper function to detect Android devices
  const isAndroid = () => {
    return /Android/i.test(navigator.userAgent) && 'ontouchstart' in window;
  };

  // Helper function to detect iOS devices
  // const isIOS = () => {
  //   return /iPhone|iPad|iPod/i.test(navigator.userAgent) && 'ontouchstart' in window;
  // };


  return (
    <div className="flex flexColumn assignment-delivery-container">
      <DriverNavbar page="ASSIGNMENT" route="assignments" />
      <div className="assignment-inner flex flexColumn">
        <h2
          className={`assignment-title ${assignment.status.toLowerCase().split(" ").join("-")}`}
        >
          {assignment.status.toLowerCase() === "waiting for driver"
            ? "Not Accepted Yet"
            : assignment.status.toLowerCase() === "being delivered"
              ? "Assigned"
              : assignment.status.toLowerCase() === "returned" ? "Returned" : "Delivered"}
        </h2>
        <div className="assignment-box flex flexColumn">
          <p className="assignment-box-label assignment-code">
            {assignment.packageDetails.name}
          </p>
          <div className="customer-container flex flexColumn">
            <p className="assignment-box-label-title">Customer</p>
            <p className="assignment-box-label assignment-driver">
              {assignment.packageDetails.ownerName}
            </p>
            <p className="assignment-box-label assignment-driver">
              {assignment.packageDetails.address}
            </p>
            <p className="assignment-box-label assignment-driver">
              {assignment.packageDetails.suburb}
            </p>
            <p className="assignment-box-label assignment-driver">
              Contact Number: <a href={`tel:${assignment.packageDetails.ownerPhone}}`}>{assignment.packageDetails.ownerPhone}</a>
            </p>
            {assignment.status !== "Delivered" && assignment?.packageDetails?.startTime ?
              (
                <p className="assignment-box-label assignment-driver delivery-time-assignment">
                  {assignment?.packageDetails?.startTime && assignment?.packageDetails?.endTime ? assignment?.packageDetails?.startTime === assignment?.packageDetails?.endTime ? `Deliver around ${assignment?.packageDetails?.startTime}` : `Deliver between ${assignment?.packageDetails?.startTime} - ${assignment?.packageDetails?.endTime}` : `Deliver around ${assignment?.packageDetails?.startTime || assignment?.packageDetails?.endTime}`}
                </p>
              )
              : null}
          </div>

          <div className="customer-container flex flexColumn">
            <p className="assignment-box-label-title">Driver</p>
            <p className="assignment-box-label assignment-driver">
              {assignment.driverName}
            </p>
          </div>

          <p className="assignment-box-label assignment-date">
            <span className="date-span">Assigned At:</span>{" "}
            {formattedDate(assignment.assignedAt)}{" "}
            <span className="date-greyed">
              {"("}
              {formatDistanceToNow(new Date(assignment.assignedAt), {
                addSuffix: true,
              })}
              {")"}
            </span>
          </p>

          {assignment.status !== "Delivered" ?
            <div className="customer-container pdf-list flex flexColumn">
              <p className="assignment-box-label-title">Files</p>
              {assignment.pdfs.map((file, index) => (
                <button
                  key={index}
                  onClick={() => viewFile(file)}
                  className="pdf-item"
                >
                  {file.split("-")[2]}
                </button>
              ))}

              <iframe title="pdf-viewer" id="pdf-container"></iframe>
            </div>
            : null}

          {assignment.status === "Being Delivered" ? (
            <>
              <label htmlFor="file-upload" className="custom-image-upload">
                Add Photos
              </label>
              {isAndroid() ?
                <input
                  id="file-upload"
                  accept="image/*"
                  capture="environment"
                  className="photo-input"
                  type="file"
                  onChange={handlePhotoUpload}
                  multiple
                />
                :
                <input
                  id="file-upload"
                  accept="image/*"
                  className="photo-input"
                  type="file"
                  onChange={handlePhotoUpload}
                  multiple
                />
              }
              {/* <input
                id="file-upload"
                accept="image/*"
                capture="environment"
                className="photo-input"
                type="file"
                onChange={handlePhotoUpload}
                multiple
              /> */}
              <div className="photo-previews flex flexColumn">

                {assignment.photos.map((photo, index) => (
                  <div className="photo-prev-cont flex flexColumn">
                    <img
                      key={index}
                      src={`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/photos/${photo}`}
                      alt={`Assignment Asset ${index}`}
                      className="photo-item"
                    />
                    <button onClick={() => handleRemoveImg(index)}>
                      Remove
                    </button>
                  </div>
                ))}
              </div>
              {assignment.photos.length > 0 && (
                <button
                  className="upload-photo-button"
                  onClick={handlePhotoSubmit}
                  disabled={buttonLoading}
                >
                  {buttonLoading ? "Loading.." : "Upload Photos & End Delivery"}
                </button>
              )}
              {assignment?.status?.toLowerCase() === "delivered" || assignment?.status?.toLowerCase() === "returned" ? null :
                <button className="d-return-delivery-button" onClick={handleReturn} disabled={buttonLoading}>
                  {buttonLoading ? "Loading.." : "Return This Package"}
                </button>
              }
            </>
          ) : assignment.status === "Delivered" ? (
            <div className="customer-container photo-list flex flexColumn">
              <div className="delivery-done">This package is delivered</div>
              <p className="assignment-box-label-title">Photos</p>
              {assignment.photos.map((photo, index) => (
                <img
                  key={index}
                  src={`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/photos/${photo}`}
                  alt={`Assignment Asset ${index}`}
                  className="photo-item"
                />
              ))}
            </div>
          ) : assignment.status === "Returned" ? (
            <div className="delivery-returned">This package is returned</div>
          ) : (
            <div className="flex flexColumn">
              <button
                className="package-picked-button"
                onClick={handlePackagePicked}
              >
                I have the package
              </button>
              {assignment?.status?.toLowerCase() === "delivered" || assignment?.status?.toLowerCase() === "returned" ? null :
                <button className="d-return-delivery-button" onClick={handleReturn} disabled={buttonLoading}>
                  {buttonLoading ? "Loading.." : "Return This Package"}
                </button>
              }
            </div>
          )}
        </div>
      </div >
    </div >
  );
};

export default AssignmentDelivery;
