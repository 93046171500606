import React from "react";
import lines from "../assets/bg-vecctor.png"

const Lines = () => {
    return (
        <div className="lines">
            <img className="lines-img" alt="" src={lines} />
        </div>
    );
};

export default Lines;
