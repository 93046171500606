import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";


import "../css/userDelivery.css";

import { useLoading } from "./LoadingContext";

const UserPage = () => {
  const { showLoading, hideLoading } = useLoading();
  const [userPackage, setUserPackage] = useState(null);
  const { uniqueId } = useParams();
  const [searchParams] = useSearchParams();

  const company = searchParams.get("c")

  useEffect(() => {
    showLoading();
    axios
      .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/delivery/${uniqueId}`)
      .then((response) => {
        setUserPackage(response.data);
      })
      .catch((error) => {
        console.error("Error fetching userPackage:", error);
      })
      .finally(hideLoading);
  }, [uniqueId]);

  if (!userPackage) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flexColumn userPackage-delivery-container">
      {
        company === "LOCKUPFIXING" ? <img
          className="user-logo"
          src={process.env.PUBLIC_URL + "/fixing-3.png"}
          alt=""
        /> : company === "MELBTRUSS" ? <img
          className="user-logo"
          src={process.env.PUBLIC_URL + "/logo3.png"}
          alt=""
        /> : null
      }
      <div className="userPackage-inner flex flexColumn">
        <h2
          className={`userPackage-title ${userPackage.status.toLowerCase() == "waiting for driver"
            ? "waiting-for-driver"
            : userPackage.status.toLowerCase() == "being delivered"
              ? "being-delivered"
              : "delivered"
            }`}
        >
          {userPackage.status.toUpperCase()}
        </h2>
        <div className="userPackage-box flex flexColumn">
          <p className="userPackage-box-label userPackage-code">
            {userPackage.packageDetails.name}
          </p>
          <div className="user-customer-container flex flexColumn">
            <p className="userPackage-box-label-title">
              {userPackage.status.toLowerCase() == "being delivered"
                ? "Your products will be delivered to"
                : "Your products have been delivered to"}
            </p>
            <p className="userPackage-box-label userPackage-driver">
              {userPackage.packageDetails.ownerName}
            </p>
            <p className="userPackage-box-label userPackage-driver">
              {userPackage.packageDetails.address}
            </p>
            <p className="userPackage-box-label userPackage-driver">
              {userPackage.packageDetails.suburb}
            </p>
          </div>

          <div className="customer-container photo-list photo-list-user flex flexColumn">
            <p className="userPackage-box-label-title">Photos</p>
            {userPackage.photos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/photos/${photo}`} // Adjust URL based on your backend route
                alt={`Photo ${index}`}
                className="photo-item-user"
              />
            ))}
          </div>
        </div>
      </div>
      <p className="support">
        If you have problems with your delivery, please contact us through{" "}
        <a href="https://melbtruss.com.au/" target="_blank">
          our website
        </a>
      </p>
    </div>
  );
};

export default UserPage;
