import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const DriverGoBack = (props) => {
  const navigate = useNavigate();

  const goHome = () => {
    const token = Cookies.get("token");
    if (!token) navigate("/login");

    navigate(-1);
  };

  return (
    <div className="goback flex flexColumn">
      <button className="goback" onClick={goHome}>
        <img src={process.env.PUBLIC_URL + "/back.png"} alt="" />
      </button>
    </div>
  );
};

export default DriverGoBack;
