import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";

import { formatDistanceToNow } from "date-fns";

import "../css/TrackShipments.css";

import { useLoading } from "./LoadingContext";
import SearchBar from "./SearchBar";
import { CompanyContext } from "./CompanyContext";

const TrackShipments = () => {
  const { showLoading, hideLoading } = useLoading();
  const [shipments, setShipments] = useState([]);
  const [skip, setSkip] = useState(0);
  const [filteredShipments, setFilteredShipments] = useState([]);
  const [filter, setFilter] = useState("all"); // State for the selected filter

  const navigate = useNavigate();
  const { company } = useContext(CompanyContext)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (!response.data.valid) {
            Cookies.remove("token");
            navigate("/login");
          } else {
            fetchShipments(token);
          }
        })
        .catch(() => {
          Cookies.remove("token");
          navigate("/login");
        })
        .finally(hideLoading);
    }
  }, [navigate]);

  const fetchShipments = (token) => {
    axios
      .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/packages`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setShipments(response.data);
        applyFilter(response.data, "all");
      })
      .catch((error) => {
        console.error("Error fetching shipments:", error);
      });
  };

  const loadMoreShipments = (token) => {
    axios
      .get(
        `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/packages/${skip + 30
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setShipments([...shipments, ...response.data]);
        setSkip(skip + 30);
      })
      .catch((error) => {
        console.error("Error fetching more shipments:", error);
      });
  };

  const applyFilter = (allShipments, selectedFilter) => {
    if (selectedFilter === "all") {
      setFilteredShipments(allShipments);
    } else {
      setFilteredShipments(allShipments.filter((shipment) => shipment.status.toLowerCase() === selectedFilter));
    }
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    applyFilter(shipments, newFilter);
  };

  return (
    <div className="flex flexColumn admin-container">
      <AdminNavbar page="TRACK DELIVERIES" route="assign" />
      <div className="track-shipments-container flex flexColumn">
        <div className="shipments-inner flex flexColumn">
          <div className="shipments-box flex flexRow">
            <div className="shipments-left flex flexColumn">
              <h2 className="driver-title">SEARCH</h2>
              <SearchBar />
            </div>
            <div className="line"></div>
            <div className="shipments-right flex flexColumn">
              <h2 className="driver-title">RECENT DELIVERIES</h2>

              {/* Filter Buttons */}
              <div className="shipment-filters flex flexRow">
                <button
                  className={`filter-button ${filter === "all" ? "filter-active" : ""}`}
                  onClick={() => handleFilterChange("all")}
                >
                  All
                </button>
                <button
                  className={`filter-button ${filter === "being delivered" ? "filter-active" : ""}`}
                  onClick={() => handleFilterChange("being delivered")}
                >
                  Assigned
                </button>
                <button
                  className={`filter-button ${filter === "delivered" ? "filter-active" : ""}`}
                  onClick={() => handleFilterChange("delivered")}
                >
                  Delivered
                </button>
              </div>
              
              <div className="shipments-right-inner flex flexRow">
                <div className="shipments-right-container flex flexRow">
                {filteredShipments.length <= 0 ? "There's nothing here yet!" : null}
                {filteredShipments.map((shipment) => (
                    <a
                      className="assignment"
                      key={shipment._id}
                      href={`track/${shipment._id}`}
                    >
                      <div
                        key={shipment._id}
                        className="shipment-item flex flexColumn"
                      >
                        <p
                          className={`shipment-box-label shipment-status ${formatStatusClass(
                            shipment.status
                          )}`}
                        >
                          <div
                            className={`dot ${
                              formatStatusClass(shipment.status) + "-dot"
                            }`}
                          ></div>
                          {shipment.status}
                        </p>
                        <p className="shipment-box-label shipment-code">
                          <span className="dropdown-hash">#</span>{" "}
                          {shipment.packageDetails.name}
                        </p>
                        <p className="shipment-box-label shipment-client">
                          {shipment.packageDetails.ownerName}
                          <br />
                          {shipment.packageDetails.address}
                        </p>
                        <p className="shipment-box-label shipment-driver">
                          <img
                            className="shipment-truck"
                            src={process.env.PUBLIC_URL + "/truckicon.png"}
                            alt=""
                          />
                          {shipment.driverName}
                        </p>
                        <p className="shipment-box-label shipment-date">
                          {formatDistanceToNow(new Date(shipment.updatedAt), {
                            addSuffix: true,
                          })}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              <button
                className="load-more-button"
                onClick={() => loadMoreShipments(Cookies.get("token"))}
              >
                See All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const formatStatusClass = (status) => {
  return status.toLowerCase().replace(/\s+/g, "-");
};

export default TrackShipments;
