import React, { useEffect, useState } from "react";
import { useLoading } from "./LoadingContext";
import "../css/Loading.css";

const Loading = () => {
  const { isLoading } = useLoading();
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    let timeout;
    if (!isLoading) {
      timeout = setTimeout(() => {
        setIsHidden(true);
      }, 1000); // Wait for 3 seconds
    } else {
      setIsHidden(false); // Reset when loading starts again
    }

    return () => clearTimeout(timeout);
  }, [isLoading])

  return (
    <div className={`loading-screen ${isLoading ? "" : "hidden"} ${isHidden ? "display-none" : ""}`}>
      {/* <div className="spinner"></div> */}
      <img
        className="loading-truck"
        src={process.env.PUBLIC_URL + "/logo-load.png"}
        alt=""
      />
      <img
        className="loading-arrow"
        src={process.env.PUBLIC_URL + "/logo-arrow.png"}
        alt=""
      />
    </div>
  );
};

export default Loading;
